<template>
    <router-view  class="minHeight"></router-view>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.minHeight {
    min-height: 100vh;
}
</style>